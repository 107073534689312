import { createTheme } from '@mui/material'
import shadows, { Shadows } from '@mui/material/styles/shadows'

// ref: https://www.figma.com/file/ZQjfwds0GrZlY0Hp5uwxep/%5Bwork%5D-DP-Portal-Renewal-%E6%A4%9C%E8%A8%8E%E7%94%A8?node-id=428%3A2441
const PRIMARY_MARINE = '#0C6993'
const LIGHT_MARINE = '#5097C4'
const DARK_MARINE = '#003F65'
const BACKGROUND_MARINE = '#E0F2F7'
const BACKGROUND_LIGHT_MARINE = '#F5F9FB'
const BACKGROUND_ORANGE = '#FFBCA0'
const BACKGROUND_CORAL = '#F7E9E8'
const FILL_LIGHT_GRAY = '#F6F7F8'
const SECONDARY_CORAL = '#F08B71'
const DISABLED = '#D5DDE2'

const BLACK = '#232323'
export const TEXT = '#4E5B61'
const TEXT_GRAY = '#899295'
const TEXT_BROWN = '#341103'
const BORDER_GRAY = '#E6EBED'
// const BACKGROUND_GRAY = '#F8F8F8';
const WHITE = '#FFFFFF'

const theme = createTheme({
  typography: {
    // default color
    allVariants: {
      color: TEXT,
    },
    // Headline-L
    h1: {
      '@media screen and (max-width:480px)': {
        fontSize: 30,
        lineHeight: 1.5,
      },
      fontSize: '2rem',
      lineHeight: 1.5,
    },
    // Headline-M
    h2: {
      '@media screen and (max-width:480px)': {
        fontSize: 24,
        lineHeight: 1.5,
      },
      fontSize: 25,
      lineHeight: 1.5,
    },
    // Headline-S
    h3: {
      '@media screen and (max-width:480px)': {
        fontSize: 21,
        lineHeight: 1.5,
      },
      fontSize: 21,
      lineHeight: 1.5,
    },
    // h4: { fontSize: 30, lineHeight: 1.5 },
    // h5: { fontSize: 30, lineHeight: 1.5 },
    // h6: { fontSize: 30, lineHeight: 1.5 },
    // Subhead
    subtitle1: {
      '@media screen and (max-width:480px)': {
        fontSize: 17,
        lineHeight: 1.75,
      },
      fontSize: 18,
      lineHeight: 1.75,
    },
    // Caption label
    subtitle2: {
      '@media screen and (max-width:480px)': {
        fontSize: 13,
        lineHeight: 1.0,
      },
      fontSize: 14,
      lineHeight: 1.0,
    },
    // Body
    body1: {
      '@media screen and (max-width:480px)': {
        fontSize: 15,
        lineHeight: 1.75,
      },
      fontSize: 16,
      lineHeight: 1.75,
    },
    // Hint
    body2: {
      '@media screen and (max-width:480px)': {
        fontSize: 15,
        lineHeight: 1.75,
      },
      fontSize: 16,
      lineHeight: 1.75,
    },
    // Caption
    caption: {
      '@media screen and (max-width:480px)': {
        fontSize: 13,
        lineHeight: 1.75,
      },
      fontSize: 14,
      lineHeight: 1.75,
    },
    // Minimal
    button: {
      '@media screen and (max-width:480px)': {
        fontSize: 11,
        lineHeight: 1.5,
      },
      fontSize: 12,
      lineHeight: 1.5,
    },
    // Password
    overline: {
      '@media screen and (max-width:480px)': {
        fontSize: 15,
        lineHeight: 1.75,
      },
      fontSize: 16,
      lineHeight: 1.75,
    },
    fontFamily: [
      'Noto Sans JP',
      'Helvetica Neue',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
  palette: {
    common: {
      black: BLACK,
      white: WHITE,
    },
    primary: {
      main: PRIMARY_MARINE,
      light: LIGHT_MARINE,
      dark: DARK_MARINE,
    },
    secondary: {
      main: SECONDARY_CORAL,
    },
    text: {
      primary: TEXT,
      secondary: TEXT_GRAY,
      brown: TEXT_BROWN,
      disabled: TEXT_GRAY,
    },
    background: {
      lightGray: FILL_LIGHT_GRAY,
      default: WHITE,
      marine: BACKGROUND_MARINE,
      lightMarine: BACKGROUND_LIGHT_MARINE,
      orange: BACKGROUND_ORANGE,
      coral: BACKGROUND_CORAL,
      disabled: DISABLED,
    },
    divider: BORDER_GRAY,
    info: {
      main: LIGHT_MARINE,
    },
    grey: {
      100: '#F6F7F8',
      200: '#C4CED4',
      300: '#D9D9D9',
      500: '#4E5B61',
    },
    contrast: {
      main: 'rgba(255, 255, 255, .5)',
      contrastText: DARK_MARINE,
    },
    disabled: {
      main: DISABLED,
      contrastText: TEXT_GRAY,
    },
  },
  shadows: [
    'none',
    'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    ...shadows.slice(2),
  ] as Shadows,
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: 42,
            fontSize: 14,
            fontWeight: 'bold',
            '@media (max-width:480px)': {
              fontSize: 13,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: 48,
            fontSize: 18,
            fontWeight: 'bold',
            '@media (max-width:480px)': {
              fontSize: 17,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: 56,
            fontSize: 18,
            fontWeight: 'bold',
            '@media (max-width:480px)': {
              fontSize: 17,
            },
          },
        },
      ],
    },
  },
})

export default theme
